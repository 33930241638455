import React, { Component } from 'react';
import LoadingScreen from './loadingScreen';
import SectionCard from '../components/sectionCard';

import Model from '../model';

export default class ProfileScreen extends Component {
	constructor(){
		super();
		this.state = {
			loading: true,
			groups: [],
		};
	}

	componentDidMount(){
		let promises = [
			Model.getMyInfo().then((info) => {
				this.setState({...info});
			}),
			Model.getGroups().then((groups) => {
				this.setState({groups: groups})
			}),
		];

		Promise.all(promises).then(() => {
			this.setState({loading: false});
		}).catch((exc) => {
			this.props.onError(exc);
		});

	}

	render() {
		if(this.state.loading) return <LoadingScreen />;

		let saveButton = null;
		if(this.state.saving){
			saveButton = <button className="btn btn-primary" disabled={true}>Saving...</button>;
		}else{
			saveButton = <button className="btn btn-primary" onClick={()=>this.save()}>Save</button>;
		}

		return (
			<div className="container page">
				<SectionCard title="My information">
					<div className="container">
						<div className="form-horizontal">
							<div className="form-group row">
								<label className="control-label col-form-label col-sm-2">Display name</label>
								<div className="col-sm-10">
									<input type="text" className="form-control" defaultValue={this.state.displayName} onChange={(e)=>this.setState({displayName: e.target.value})} />
								</div>
							</div>

							<div className="form-group row">
								<div className="col-sm-12 text-right">
									{saveButton}
								</div>
							</div>
						</div>
					</div>

				</SectionCard>

				<SectionCard title="My groups">
					{
						this.state.groups.length === 0 ? (
							<div>
								<p>You don't belong to any groups yet :(</p>
								<p>Please ask your team leader to grant you access. They'll need to know your email address.</p>
							</div>
						):(
							this.state.groups.map((group) => {
								return <button key={group.id}
									onClick={()=>this.selectGroup(group)}
									className="btn btn-primary"
									style={{margin:'0.25em'}}
								>{group.name}</button>;
							})
						)
					}
				</SectionCard>
			</div>
		);
	}

	selectGroup(group){
		this.props.history.push('/groups/' + group.id);
	}

	save(){
		this.setState({saving:true});
		Model.saveMe(this.state).then(() => {
			this.setState({saving:false});
		})
	}
}
