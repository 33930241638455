import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

import env from './env';

firebase.initializeApp(env.config);
firebase.firestore().settings({timestampsInSnapshots: true});
class Model {
	constructor(){
		this.loggedIn = false;
		this.pendingOperations = [];

		this.auth = firebase.auth();
		this.auth.onAuthStateChanged((user) => {
			this.loggedIn = Boolean(user);
		});
	}

	waitForLogin(){
		return new Promise((resolve, reject) => {
			let checker = setInterval(()=>{
				if(this.loggedIn){
					resolve();
					clearInterval(checker);
				}
			}, 100);
		});
	}

	signOut(){
		return this.auth.signOut();
	}

	async getGroups(){
		/*
			Retrieve a list of groups for which the authenticated user is enrolled

			Returns [{id:'...', name:'...'}, ...]
		*/
		
		await this.waitForLogin();

		var userID = firebase.auth().currentUser.uid;
		var usersGroups = firebase.firestore().collection('/users/' + userID + '/groups');
		let enrollmentSnapshot = await usersGroups.get();

		if(enrollmentSnapshot.size > 0){
			var groups = [];
			enrollmentSnapshot.forEach((doc) => {
				groups.push({
					'id': doc.id,
					'name': doc.data().name,
				});
			});

			groups.sort((a,b) => a.name > b.name);
			
			return groups;
		}else{
			return [];
		}
	}

	async getGroupInfo(groupID) {
		let snapshot = await firebase.firestore().collection('groups').doc(groupID).get();
		return snapshot.data();
	}

	async getTours(groupID) {
		/*
			Retrieve a list of tours for the specified group

			Returns [{id:'...', ...}, ...]
		*/
		await this.waitForLogin();

		var toursRef = firebase.firestore().collection('groups').doc(groupID).collection('tours');

		let toursSnapshot = await toursRef.orderBy('name').get();
		let tours = toursSnapshot.docs.map((doc) => { return {'id':doc.id, ...doc.data()}});

		return tours;
	}

	async updateTourKML(groupID, tourID, file){
		await this.waitForLogin();

		let token = await firebase.auth().currentUser.getIdToken(true);

		var formData  = new FormData();
		formData.append('groupID', groupID);
		formData.append('tourID', tourID);
		formData.append('file', file);

		let response = await fetch('/functions/uploadKML', {
			method: 'POST',
			body: formData,
			headers: {
				'Authorization': 'Bearer ' + token
			},
		});

		let json = await response.json();
		if(!response.ok){
			throw json;
		}
		return json;
	}

	async addTour(groupID, file){
		await this.waitForLogin();
		
		let userID = firebase.auth().currentUser.uid;
		let toursRef = firebase.firestore().collection('groups').doc(groupID).collection('tours');

		let tourRef = await toursRef.add({
			'groupID': groupID,
			'creatorID': userID,
		});
		
		return this.updateTourKML(groupID, tourRef.id, file);
	}

	async getTour(groupID, tourID){
		await this.waitForLogin();

		let tourRef = firebase.firestore().collection('groups').doc(groupID).collection('tours').doc(tourID);
		let tourDoc = await tourRef.get()

		return tourDoc.data();
	}

	async updateTour(groupID, tourID, tour){
		await this.waitForLogin();

		let tourRef = firebase.firestore().collection('groups').doc(groupID).collection('tours').doc(tourID);
		await tourRef.update(tour);
	}

	async deleteTour(groupID, tourID){
		await this.waitForLogin();

		let tourRef = firebase.firestore().collection('groups').doc(groupID).collection('tours').doc(tourID);
		await tourRef.delete();
	}

	async getUsers(groupID){
		await this.waitForLogin();

		let groupUsersRef = firebase.firestore().collection('groups').doc(groupID).collection('users');

		let groupUsersSnapshot = await groupUsersRef.orderBy('displayName').get();
		let users = groupUsersSnapshot.docs.map((doc) => { return {'id':doc.id, ...doc.data()}});

		return users;
	}

	async sendInvitations(groupID, emails){
		await this.waitForLogin();

		let invitationsRef = firebase.firestore().collection('groups/' + groupID + '/invitations');
		let invitePromises = [];
		for(let email of emails){
			invitePromises.push(
				invitationsRef.add({
					'creatorID': firebase.auth().currentUser.uid,
					'email': email,
					'status': 'sending',
				})
			);
		}

		await Promise.all(invitePromises);
	}

	async getInviteInfo(groupID, invitationID){
		let inviteRef = await firebase.firestore().doc('groups/'+groupID+'/invitations/'+invitationID).get();
		return inviteRef.data();
	}

	acceptInvitation(groupID, invitationID){
		return new Promise(async (resolve, reject) => {
			await this.waitForLogin();

			console.log('Accepting invitation');

			let userID = firebase.auth().currentUser.uid;

			firebase.firestore().doc('groups/'+groupID+'/invitations/'+invitationID).update({userID:userID});
			let enrollmentRef = firebase.firestore().doc('users/'+userID+'/groups/'+groupID);

			var listener = enrollmentRef.onSnapshot((snapshot) => {
				var data = snapshot.data();
				if(data){
					resolve();
					listener();
				}
			});
		});
	}

	async getMyInfo(){
		await this.waitForLogin();

		let basicUserData = firebase.auth().currentUser;

		let userID = firebase.auth().currentUser.uid;
		let userRef = await firebase.firestore().collection('users').doc(userID).get();
		let extraUserData = userRef.data();

		return {...basicUserData, ...extraUserData};
	}

	async saveMe(info){
		await this.waitForLogin();

		let fields = ['displayName', 'email', 'photoUrl'];
		let values = {};

		for(let key of Object.keys(info)){
			if(fields.indexOf(key) > -1){
				values[key] = info[key];
			}
		}

		let extraUserRef = firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid);
		await extraUserRef.update(values);
	}

	async removeUser(groupID, userID){
		await this.waitForLogin();

		let userRef = firebase.firestore().collection('groups').doc(groupID).collection('users').doc(userID);
		await userRef.delete();
	}

	async promoteUser(groupID, userID){
		await this.waitForLogin();

		let userRef = firebase.firestore().collection('groups').doc(groupID).collection('users').doc(userID);
		await userRef.update({isAdmin:true});
	}

	async demoteUser(groupID, userID){
		await this.waitForLogin();

		let userRef = firebase.firestore().collection('groups').doc(groupID).collection('users').doc(userID);
		await userRef.update({isAdmin:false});
	}
};

export default new Model();