import React, { Component } from 'react';

import LoadingScreen from './loadingScreen';
import UploadStatusComponent from '../components/uploadStatus';

import Model from '../model';

export default class GroupToursScreen extends Component {
	constructor(){
		super();
		this.state = {
			'loading': true,
			'uploading': false,
			'uploadProgress': 0,
			'processing': false,
			'selectedTour': null,
		};
	}

	componentWillMount(){
		this.groupID = this.props.match.params.groupID;
	}

	componentDidMount(){
		let promises = [];
		promises.push(
			Model.getGroupInfo(this.groupID).then((groupInfo) => {
				this.setState({...groupInfo});
			})
		);

		promises.push(
			Model.getTours(this.groupID).then((tours) => {
				this.setState({tours:tours});
			})
		);

		Promise.all(promises).then(()=> {
			this.setState({loading:false});
		}).catch((exc) => {
			this.props.onError(exc);
		});
	}

	render() {
		if(this.state.loading) return <LoadingScreen />;

		return (
			<div className="container">
				<h2>{this.state.name}</h2>
				{ this.state.uploading || this.state.processing ? <UploadStatusComponent {...this.state} />:null}

				<div className="tourContainer">
					<label style={{cursor:'pointer'}}>
						<input
							type="file"
							onChange={(e) => this.uploadFile(e.target.files[0])} style={{display:'none'}}
							accept=".kml,.xml"
						/>
						<TourComponent name="+ Add a tour" description="Create a new tour from a KML file" imageURL="/add-new.png"/>
					</label>
					{
						this.state.tours.length === 0 ? (
							<div>
								<p>There are no tours yet! Looks like you're the lucky one to be first!</p>
								<p>Use the '+' button below to upload a KML file.</p>
							</div>
						):(
							this.state.tours.map((tour) => {
								return <TourComponent key={tour.id}
									{...tour}
									onClick={()=>this.selectTour(tour)}
									onUpload={(f)=>this.uploadFile(f, tour.id)}
								/>
							})
						)
					}
				</div>
			</div>
		);
	}

	selectTour(tour){
		this.props.history.push('/groups/' + this.groupID + '/tours/' + tour.id);
	}

	uploadFile(file, tourID){
		this.setState({uploading: true});

		if(tourID){
			Model.updateTourKML(this.groupID, tourID, file).then((tour) => {
				this.setState({uploading: false});
				this.props.onMessage(tour.name + ' has been updated!');
				let tours = this.state.tours;
				for(let i=0; i<tours.length; i++){
					if(tours[i].id === tourID){
						tours[i] = {...tours[i], ...tour};
						break;
					}
				}
				this.setState({tours:tours});
			}).catch((exc)=>{
				this.setState({uploading: false});
				this.props.onError(exc);
			});
		}else{
			Model.addTour(this.groupID, file).then((tour) => {
				this.setState({uploading: false});
				this.selectTour(tour);
				this.props.onMessage(tour.name + ' has been created!');
			}).catch((exc)=>{
				this.setState({uploading: false});
				this.props.onError(exc);
			});
		}
	}

	handleError(error, msg){
		if(this.props.onError){
			this.props.onError(error, msg);
		}else{
			console.error(error, msg);
		}
	}

	handleMessage(msg){
		if(this.props.onMessage){
			this.props.onMessage(msg);
		}else{
			alert(msg);
		}
	}
}

class TourComponent extends Component {
	render(){
		var description = this.props.description;
		if(description.length > 63){
			description = description.substring(0, 61) + '...';
		}
		return (
			<div className='tourTile'>
				<div className='card' onClick={()=>(this.props.onClick && !this.props.id) ? this.props.onClick() : null}>
					<div className="card-header">
						{this.props.name}
					</div>
					<img
						className="card-img preview"
						src={this.props.imageURL}
						alt={this.props.name + ' preview'}
					/>
					<div className="card-body">
						{
							this.props.id ? (
								<div className="btn-group" style={{width:'100%',textAlign:'center',justifyContent:'center'}}>
									<label className="btn btn-sm btn-primary" onClick={()=>this.props.onClick()}>Edit</label>
									<label className="btn btn-sm btn-warning">
										<input
											type="file"
											onChange={(e) => this.props.onUpload(e.target.files[0])} style={{display:'none'}}
											accept=".kml,.xml"
										/>
										Update KML
									</label>
								</div>
							):null
						}
						<p className="card-text">
							{this.props.distance !== undefined ? <span>Distance: {this.props.distance || '-'} mi<br/></span> : null}
							{description}
						</p>
					</div>
					<div className="card-body">
						{
							this.props.tags ? this.props.tags.map((tag) => 
								<span key={tag} className="tour-tag">{tag}</span>
							):null
						}
					</div>
				</div>
			</div>
		)
	}
}


