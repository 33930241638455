const configs = {
	production: {
		apiKey: "AIzaSyAXSJk9qBM6N2UQGfBPT5od4Etyrooeh8s",
		authDomain: "lets-scout-about.firebaseapp.com",
		databaseURL: "https://lets-scout-about.firebaseio.com",
		projectId: "lets-scout-about",
		storageBucket: "lets-scout-about.appspot.com",
		messagingSenderId: "1097293499647"
	},
	staging:{
		apiKey: "AIzaSyDIOOVVcqKUTnvjj3MmM-OsDFHK0OLQG-Y",
		authDomain: "lets-scout-about-staging.firebaseapp.com",
		databaseURL: "https://lets-scout-about-staging.firebaseio.com",
		projectId: "lets-scout-about-staging",
		storageBucket: "lets-scout-about-staging.appspot.com",
		messagingSenderId: "1060159054782"
	}
};

class Environment {
	constructor(){
		this.host = window.location.hostname;
		if(this.host === 'letsscoutabout.com'){
			this.production = true;
			this.config = configs.production;
			this.baseURL = 'https://' + this.host;
		}else{
			this.production = false;
			this.config = configs.staging;
			this.baseURL = 'http://' + this.host + ':5000';
			console.log('STAGING');
		}
	}
}

export default new Environment();
