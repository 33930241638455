import React, { Component } from 'react';

export default class UploadStatusComponent extends Component {
	render(){
		return (
			<div className="modal">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h4 className="modal-title">Please wait...</h4>
						</div>
						<div className="modal-body" style={{textAlign:'center'}}>
							{
								this.props.uploading ? (
									<h2>Uploading</h2>
								):(
									this.props.processing ? (
										<h2>Processing</h2>
									):null
								)
							}
							<h3><span role="img" aria-label="Hourglass">⏳</span></h3>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
