import React, { Component } from 'react';
import {
	BrowserRouter,
	Route,
	Link,
	Switch
} from 'react-router-dom';

import logo from './graphics/logo.svg';
import drawLineIcon from './graphics/draw-line-icon.svg';
import './App.css';

import LoginScreen from './screens/loginScreen';
import ProfileScreen from './screens/profileScreen';
import GroupScreen from './screens/groupScreen';
import GroupToursScreen from './screens/groupToursScreen';
import TourScreen from './screens/tourScreen';
import InvitationScreen from './screens/invitationScreen';
import NotFoundScreen from './screens/notFoundScreen';

import env from './env';
import Model from './model';

class App extends Component {
	constructor(){
		super();

		this.pages = {
			'/': (props) => <LoginScreen {...this.bindMessaging(props)}/>,
			'/login': (props) => <LoginScreen {...this.bindMessaging(props)}/>,
			'/me': (props) => <ProfileScreen {...this.bindMessaging(props)}/>,
			'/groups/:groupID': (props) => <GroupScreen {...this.bindMessaging(props)}/>,
			'/groups/:groupID/tours': (props) => <GroupToursScreen {...this.bindMessaging(props)}/>,
			'/groups/:groupID/tours/:tourID': (props) => <TourScreen {...this.bindMessaging(props)}/>,
			'/groups/:groupID/invitation/:invitationID': (props) => <InvitationScreen {...this.bindMessaging(props)}/>,
		}

		this.state = {
			errors: [],
			messages: [],
			loggedIn: false,
			showHelp: false,
		};

		this.router = React.createRef();
	}

	componentDidMount(){
		Model.auth.onAuthStateChanged((user) => {
			this.setState({loggedIn:Boolean(user)});
		});
	}

	bindMessaging(props){
		return {
			...props,
			onMessage: (msg) => this.onMessage(msg),
			onError: (error, msg) => this.onError(error, msg),
		};
	}

	render() {
		return (
			<BrowserRouter ref={this.router}>
				<div className="App container" style={env.production ? null:{backgroundColor:'#f009'}}>
					{
						env.production ? null:(
							<h3 style={{textAlign:'center', paddingTop:'0.5em'}}>STAGING</h3>
						)
					}
					<header className="jumbotron container">
						<div className="title">
							<Link to="/"><img src={logo} className="App-logo" alt="Let's Scout About Logo" /></Link>
							<h1>Let's Scout About!</h1>
						</div>
						{
							this.state.loggedIn ? (
								<nav>
									<Link to="/me">My profile</Link>
									<button className="btn btn-link" onClick={()=>this.showHelp()}>Help</button>
									<button className="btn btn-link" onClick={()=>this.signout()}>Sign out</button>
								</nav>
							):null
						}
					</header>

					{this.state.errors.length > 0 ? (
						<div className="modal">
							<div className="modal-dialog">
								<div className="modal-content">

									<div className="modal-header">
										<h4 className="modal-title">Something went wrong :(</h4>
									</div>

									<div className="modal-body">{
										this.state.errors.map((message, id) => {
											return <div className="message" key={id}>
												{message}
											</div>;
										})
									}</div>

									<div className="modal-footer">
										<button type="button" className="btn btn-danger" onClick={()=>this.clearErrors()}>Close</button>
									</div>
								</div>
							</div>
						</div>
					):null}

					{this.state.messages.length > 0 ? (
						<div className="modal">
							<div className="modal-dialog">
								<div className="modal-content">
									<div className="modal-body" style={{paddingTop: '3em', paddingBottom: '3em'}}>
										{
											this.state.messages.map((message, id) => {
												return <div className="message" key={id}>
														{message}
													</div>
											})
										}
									</div>
									<div className="modal-footer">
										<button type="button" className="btn btn-primary" onClick={()=>this.clearMessages()}>Close</button>
									</div>
								</div>
							</div>
						</div>
					):null}

					{this.state.showHelp ? (
						<div className="modal">
							<div className="modal-dialog">
								<div className="modal-content">
									<div className="modal-header">
										<h4 className="modal-title">Map help</h4>
									</div>

									<div className="modal-body">
										<h5>Experienced map makers</h5>
										<ul>
											<li>
												<a href="https://www.google.com/maps/d" target="_blank" rel="noopener noreferrer">Create a map</a> with <strong>two</strong> layers - one for points of interest and one for the route.
												<ul>
												<li>Each point of interest should have a title, description, and image (<code><strong>1024</strong>x<strong>768</strong>px</code>).</li>
												<li>Use the <img alt="draw line icon" src={drawLineIcon} width={21} height={21} /><code>Draw a Line</code> tool to create your route.</li>
												</ul>
											</li>
											<li>Export the entire map to a <code>.KML</code> file and save it on your computer.</li>
											<li>Upload the <code>.KML</code> file to this website.</li>
										</ul>

										<h5>Novice map makers</h5>
										<p>Check out our <a href="https://goo.gl/hZBTqJ" target="_blank" rel="noopener noreferrer">training slides</a> for a complete walk-through!</p>
									</div>
									<div className="modal-footer">
										<button type="button" className="btn btn-primary" onClick={()=>this.setState({showHelp:false})}>Close</button>
									</div>
								</div>
							</div>
						</div>
					):null}

					<Switch>
						{
							Object.keys(this.pages).map((routePath) => {
								return <Route key={routePath}
									exact
									path={routePath}
									component={this.pages[routePath]}
								/>;
							})
						}
						<Route component={NotFoundScreen} />
					</Switch>
				</div>
			</BrowserRouter>
		)
	}

	onMessage(message){
		this.state.messages.push(message);
		this.setState({messages: this.state.messages});

		if(this.autoClearMessages){
			this.messageClearTimeout = window.setTimeout(()=>{
				this.clearMessages()
			}, 3000);
		}
	}

	onError(error, msg){
		if(!msg) msg = 'Uh oh!';

		console.log('ERROR [' + msg + ']');
		console.error(error);

		var errorCode = error.code;
		var errorMessage = error.message;
		this.state.errors.push(<div title={'['+errorCode+'] '+errorMessage}>{msg} {errorMessage}</div>);
		this.setState({errors: this.state.errors});
	}

	clearMessages(){
		window.clearTimeout(this.messageClearTimeout);
		this.setState({messages:[]});
	}

	clearErrors(){
		this.setState({errors:[]});
	}

	signout(){
		Model.signOut().then(()=>{
			this.router.current.history.push('/');
		});
	}

	showHelp(){
		this.setState({showHelp: true});
	}
}

export default App;
