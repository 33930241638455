import React, { Component } from 'react';

export default class SectionCard extends Component {
	render() {
		return (
			<div className='card'>
				<div className="card-header">
					<div className="float-right">{this.props.buttons || null}</div>
					<h4>{this.props.title}</h4>
				</div>
				<div className="card-body">
					{this.props.children}
				</div>
			</div>
		);
	}
}