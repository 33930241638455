import React, { Component } from 'react';

import LoadingScreen from './loadingScreen';
import SectionCard from '../components/sectionCard';

import Model from '../model';

export default class GroupScreen extends Component {
	constructor(){
		super();
		this.state = {
			'loading': true,
		};
	}

	componentWillMount(){
		this.groupID = this.props.match.params.groupID;
	}

	componentDidMount(){
		let promises = [];
		promises.push(
			Model.getGroupInfo(this.groupID).then((groupInfo) => {
				this.setState({...groupInfo});
			})
		);

		promises.push(
			Model.getUsers(this.groupID).then((users) => {
				this.setState({users:users});
			})
		);

		Promise.all(promises).then(()=> {
			this.setState({loading:false});
		}).catch((exc) => {
			if(exc.code && exc.code === 'permission-denied'){
				this.props.history.push('/groups/' + this.groupID + '/tours');
			}else{
				this.props.onError(exc);
			}
		});
	}

	render() {
		if(this.state.loading) return <LoadingScreen />;

		let addUserButton =
			<div className="btn btn-secondary" onClick={()=>this.setState({showInvitation:true})}>
				<span role="img" aria-label="Add user">+👤</span>
			</div>;

		return (
			<div className="container page">
				<h2>{this.state.name}</h2>

				<SectionCard title="Tours">
					<button className="btn btn-primary" onClick={()=>this.props.history.push('/groups/' + this.groupID + '/tours')}>Manage tours</button>
				</SectionCard>

				<SectionCard title="Users" buttons={addUserButton}>
					<table className="table table-bordered table-striped table-hover">
						<thead>
							<tr>
								<th>Actions</th>
								<th>Name</th>
								<th>Email</th>
								<th>Status</th>
							</tr>
						</thead>
						<tbody>
							{
								this.state.users.map((user) => {
									return <tr key={user.id}>
											<td>
												<button disabled={user.isAdmin} className="btn btn-sm btn-outline-secondary" title={'Promote '+user.displayName+' to administrator'} onClick={()=>this.promoteUser(user)}>
													<span role="img" aria-label="Promote icon">👑</span>
												</button>
												<button disabled={!user.isAdmin} className="btn btn-sm btn-outline-secondary" title={'Demote '+user.displayName+' to plain ol\' member'} onClick={()=>this.demoteUser(user)}>
													<span role="img" aria-label="Demote icon">📍</span>
												</button>
												<button className="btn btn-sm btn-outline-danger" title={'Remove '+user.displayName+' from group'} onClick={()=>this.removeUser(user)}>
													<span role="img" aria-label="Remove icon">✗</span>
												</button>
											</td>
											<td>{user.displayName}</td>
											<td>{user.email}</td>
											<td>{user.isAdmin ? '👑 Administrator' : '📍 Member'}</td>
										</tr>
								})
							}
						</tbody>
					</table>
				</SectionCard>

				{
					this.state.showInvitation ? (
						<div className="modal">
							<div className="modal-dialog">
								<div className="modal-content">
									<div className="modal-header h4">Invite users</div>
									{
										this.state.invitationsSending ? (
											<div>
												<div className="modal-body">
													<p>Sending...</p>
												</div>
											</div>
										):(
											<div>
												<div className="modal-body">
													<p>Enter email addresses below of the people you'd like to invite to participate. Each user will receive an email with a link for them to join the {this.state.name} group.</p>
													<label className="control-label" style={{width:'100%'}}>
														Email addresses<br/>
														<textarea className="form-control" onChange={(e)=>this.setState({'inviteEmails': e.target.value})} />
													</label>
												</div>
												<div className="modal-footer">
													<button className="btn btn-link" onClick={()=>this.setState({showInvitation:false})}>Cancel</button>
													<button className="btn btn-primary" onClick={()=>this.sendInvitations()}>Send invites</button>
												</div>
											</div>
										)
									}
								</div>
							</div>
						</div>
					):null
				}
			</div>
		)
	}

	sendInvitations(){
		this.setState({invitationsSending: true});
		let emails = this.state.inviteEmails.split(/[ \t\n\r,]+/);

		Model.sendInvitations(this.groupID, emails).then(() => {
			this.props.onMessage('Invitations have been created and will be sent soon :)');

			this.setState({
				showInvitation: false,
				invitationsSending: false,
			});
		});
	}

	promoteUser(user){
		Model.promoteUser(this.groupID, user.id).then(() => {
			var users = [];
			for(let currentUser of this.state.users){
				if(user.id === currentUser.id){
					user.isAdmin = true;
				}
				users.push(currentUser);
			}
			this.setState({users:users});
		}).catch((exc)=>{
			this.props.onError(exc);
		});
	}

	demoteUser(user){
		if(user.id === Model.auth.currentUser.uid){
			if(!window.confirm('Whoa - you are about to demote YOURSELF in ' + this.state.name + '!')){
				return;
			}
		}
		Model.demoteUser(this.groupID, user.id).then(() => {
			var users = [];
			for(let currentUser of this.state.users){
				if(user.id === currentUser.id){
					user.isAdmin = false;
				}
				users.push(currentUser);
			}
			this.setState({users:users});
		}).catch((exc)=>{
			this.props.onError(exc);
		});
	}
	
	removeUser(user){
		let who = user.displayName || 'the user';
		if(user.email){
			who += ' <' + user.email + '>';
		}
		if(window.confirm('Caution: this will REMOVE ' + who + ' from ' + this.state.name + '!')){
			if(user.id === Model.auth.currentUser.uid){
				if(!window.confirm('Whoa - you are about to remove YOURSELF from ' + this.state.name + '!')){
					return;
				}
			}
			Model.removeUser(this.groupID, user.id).then(() => {
				var users = [];
				for(let currentUser of this.state.users){
					if(user.id !== currentUser.id){
						users.push(currentUser);
					}
				}
				this.setState({users:users});
			}).catch((exc) =>{
				this.props.onError(exc);
			});
		}
	}
}
