import React, { Component } from 'react';

export default class NotFoundScreen extends Component {

	render() {
		return (
			<div className="container">
				<h4>The item you requested could not be found :(</h4>
				<div className="btn btn-primary" onClick={()=>this.props.history.goBack()}>Go back</div>
			</div>
		);
	}
}