import React, { Component } from 'react';
import LoadingScreen from './loadingScreen';

import Model from '../model';

export default class InvitationScreen extends Component {
	constructor(){
		super();
		this.state = {
			loading: true,
			email: null,
			password: null,
			passwordConfirm: null,
			creating: false,
		};

		this.passwordInput = React.createRef();
		this.passwordConfirmInput = React.createRef();
	}

	componentDidMount(){
		Model.signOut();

		this.groupID = this.props.match.params.groupID;
		this.invitationID = this.props.match.params.invitationID;

		Model.auth.onAuthStateChanged((user) => {
			if(user){
				this.setState({loading:true});
				Model.acceptInvitation(this.groupID, this.invitationID).then(()=>{
					this.props.history.push('/me');
					this.props.onMessage('Welcome!');
				});
			}
		});
		
		Model.getInviteInfo(this.groupID, this.invitationID).then((invitation) => {
			if(!invitation){
				this.props.onMessage('The requested invitation is not valid.');
				this.props.history.push('/');
			}else{
				this.setState({
					invitation: invitation,
					loading: false,
					email: invitation.email,
				});
				window.setTimeout(()=>{this.passwordInput.current.focus()}, 500);
			}
		});
	}

	render() {
		if(this.state.loading) return <LoadingScreen />;

		//<div><button onClick={()=>this.loginWithGoogle()}>Login with Google</button></div>
		return (
			<div className="container">
				<h4 style={{textAlign:'center',marginBottom:'1rem'}}>You must login or create an account to accept this invitation</h4>

				<form className="form-horizontal" onSubmit={(e)=>{ this.formSubmit(); e.preventDefault(); }}>
					<div className="form-group row">
						<label className="control-label col-form-label col-sm-2">Email</label>
						<div className="col-sm-10">
							<input className="form-control" type="email" placeholder="email@example.com" onChange={(e)=>this.setState({email:e.target.value})} defaultValue={this.state.email}/><br/>
						</div>
					</div>

					<div className="form-group row">
						<label className="control-label col-form-label col-sm-2">Password</label>
						<div className="col-sm-10">
							<input
								className="form-control"
								type="password"
								placeholder="Password" 
								onChange={(e)=>this.setState({password:e.target.value})}
								ref={this.passwordInput}
							/><br/>
						</div>
					</div>

					{
						this.state.creating ? (
							<div>
								<div className="offset-sm-2">
									This account doesn't exist, but we can create it for you!
								</div>

								<div className="form-group row ">
									<label className="control-label col-form-label col-sm-2">Confirm password</label>
									<div className="col-sm-10">
										<input
											className="form-control"
											type="password"
											placeholder="Confirm password"
											onChange={(e)=>this.setState({passwordConfirm:e.target.value})}
											ref={this.passwordConfirmInput}
										/><br/>
									</div>
								</div>
							</div>
						):null
					}{
						this.state.creating ? (
							<div className="offset-sm-2">
								<input
									type="submit"
									className="btn btn-primary"
									value="Create account and accept invitation"
								/>
								<div className="btn btn-link" onClick={()=>this.setState({creating:false})}>Cancel</div>
							</div>
						):(
							<div className="offset-sm-2">
								<input type="submit" className="btn btn-primary" value="Login and accept invitation" />
							</div>
						)
					}
				</form>
			</div>
		);
	}

	formSubmit(){
		if(this.state.creating){
			if(this.state.password === this.state.passwordConfirm){
				this.createAccount();
			}else{
				this.props.onMessage('Your passwords don\'t match :(');
			}
		}else{
			this.login();
		}
	}

	createAccount(){
		var email = this.state.email;
		var password = this.state.password;
		Model.auth.createUserWithEmailAndPassword(email, password).catch((error) => {
			this.props.onError(error);
		}).then(()=>{
			this.login();
		});
	}

	login(){
		var email = this.state.email;
		var password = this.state.password;
	
		Model.auth.signInWithEmailAndPassword(email, password).catch((error) => {
			if(error.code === 'auth/user-not-found'){
				// User doesn't exist yet...
				this.setState({'creating': true});
				this.passwordConfirmInput.current.focus();
			}else{
				this.props.onError(error, 'Login failed');
			}
		}).then(() => {});
	}
}