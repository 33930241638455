import React, { Component } from 'react';

export default class LoadingScreen extends Component {
	render() {
		let text = this.props.text || 'Loading';
		return (
			<div className="container">
				<h2 className="loading" style={{textAlign:'center'}}>
					<span role="img" aria-label="">⏳</span> {text}...
				</h2>
			</div>
		);
	}
}