import React, { Component } from 'react';

import LoadingScreen from './loadingScreen';
import UploadStatusComponent from '../components/uploadStatus';

import Model from '../model';

export default class TourScreen extends Component {
	constructor(){
		super();
		this.state = {
			'loading': true,
			'uploading': false,
		};
	}

	componentWillMount(){
		this.groupID = this.props.match.params.groupID;
		this.tourID = this.props.match.params.tourID;
	}

	componentDidMount(){
		Model.getTour(this.groupID, this.tourID).then(tour => {
			this.setState({
				loading: false,
				...tour,
			})
		}).catch((exc) => {
			this.props.onError(exc);
		});
	}

	render() {
		if(this.state.loading) return <LoadingScreen />;

		let location = this.state.location || [0,0];
		let tags = this.state.tags || [];

		let notes = 'Note 1: this is a new feature in v0.2.2 of the app. Older versions of the app will display *all* tours.\n\n'
			+ 'Note 2: if you want to see non-public tours in new versions of the app, enable debug mode by going to the support page and tapping on the heart 5x. It will change color to confirm your setting, and the tour list will now display public and non-public tours.';

		return (
			<div className="container">
				{ this.state.uploading || this.state.processing ? <UploadStatusComponent {...this.state} />:null}
				<h3>{this.state.name ? this.state.name : '[Unnamed tour]'}</h3>
				<div className="form-horizontal">
					<div className="form-group row">
						<label className="control-label col-form-label col-sm-2">Name</label>
						<div className="col-sm-10">
							<input type="text" className="form-control" value={this.state.name} onChange={(e)=>this.setState({'name': e.target.value})} />
						</div>
					</div>

					<div className="form-group row">
						<label className="control-label col-form-label col-sm-2">Description</label>
						<div className="col-sm-10">
							<textarea className="form-control" value={this.state.description} onChange={(e)=>this.setState({'description': e.target.value})} />
						</div>
					</div>

					<div className="form-group row">
						<label className="control-label col-form-label col-sm-2">KML</label>
						<div className="col-sm-10">
							<a target="_blank" href={this.state.fileURL} className="btn btn-sm btn-link">Download current KML</a>
						</div>
					</div>

					<div className="form-group row">
						<label className="control-label col-form-label col-sm-2">Public</label>
						<div className="col-sm-10">
							<div className="form-check">
								<label className="form-check-label" title={notes}>
									<input type="checkbox" className="form-check-input" onChange={(e)=>this.setState({'public': e.target.checked})} /> 
									{
										this.state.public ? ' Checked = Visible in the app for everybody*' : ' Unchecked = Not visible in the app*'
									}
								</label>
							</div>
						</div>
					</div>

					<div className="form-group row">
						<label className="control-label col-form-label col-sm-2">Thumbnail</label>
						<div className="col-sm-10">
							{
								this.state.imageURL && this.state.imageURL !== '' ? (
									<img src={this.state.imageURL} alt='Thumbnail preview' style={{maxWidth: '100%'}}/>
								):(
									<span>No preview image</span>
								)
							}
						</div>
					</div>

					<div className="form-group row">
						<label className="control-label col-form-label col-sm-2">Location</label>
						<div className="col-sm-5">
							<input type="number" className="form-control" placeholder="latitude" defaultValue={location[0]} />
						</div>
						<div className="col-sm-5">
							<input type="number" className="form-control" placeholder="longitude" defaultValue={location[1]} />
						</div>
					</div>

					<div className="form-group row">
						<label className="control-label col-form-label col-sm-2">Distance</label>
						<div className="col-sm-10">
							<input type="number" className="form-control" defaultValue={this.state.distance} onChange={(e)=>this.setState({'distance': e.target.value})}  />
						</div>
					</div>

					<div className="form-group row">
						<label className="control-label col-form-label col-sm-2">Tags</label>
						<div className="col-sm-10">
							<input className="form-control" defaultValue={tags.join(', ')} onChange={(e)=>this.setTags(e.target.value)} />
						</div>
					</div>
					
					<div className="form-group row">
						<div className="offset-sm-2 col-sm-2">
							<button className="btn btn-danger" onClick={()=>this.delete()}>Delete</button>
						</div>
						<div className="col-sm-8 text-right">
							<button className="btn btn-link" onClick={()=>this.props.history.goBack()}>Cancel</button>
							<button className="btn btn-primary" onClick={()=>this.save()}>Save</button>
						</div>
					</div>

					<div className="form-group row">
						<label className="control-label col-form-label col-sm-2">Issues</label>
						<div className="col-sm-10">
							{
								!this.state.errors || this.state.errors.length === 0 ? (
									<span>No problems detected</span>
								):(
									<div>
										<span>This route has {this.state.errors.length} problem{this.state.errors.length > 1 ? 's':''}:</span>
										<ul>
											{
												this.state.errors.map((err, i) => {
													return <li key={i}>{err}</li>
												})
											}
										</ul>
									</div>
								)
							}
						</div>
					</div>


				</div>
			</div>
		);
	}

	save(){
		this.setState({loading:true});
		var record = {};
		for(var field of Object.keys(this.state)){
			if(field !== 'loading'){
				record[field] = this.state[field];
			}
		}
		
		Model.updateTour(this.groupID, this.tourID, record).then(() => {
			this.props.history.goBack();
		});
	}

	delete(){
		if(window.confirm('Are you sure you want to DELETE "' + this.state.name + '"?\n\nThis action cannot be undone.')){
			this.setState({'loading': true});
			Model.deleteTour(this.groupID, this.tourID).then(() => {
				this.props.onMessage('"' + this.state.name + '" was deleted forever');
				this.props.history.goBack();
			});
		}
	}

	setTags(tagString){
		var tags = tagString.split(',').map((tag) => tag.trim().toLowerCase());
		this.setState({'tags': tags});
	}
}
